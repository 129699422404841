import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDescription: description
        author
        siteUrl
        image
        twitterUsername
      }
    }
  }
`

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(getData)

  const { siteDescription, siteTitle, siteUrl, image, twitterUsername } =
    site.siteMetadata

  return (
    <Helmet htmlAttributes={{ lang: "en" }} title={`${title} ${siteTitle}`}>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <script type="application/ld+json">{JSON.stringify(description)}</script>
      {/* twitter card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={siteDescription} />
      {/* <meta name="twitter:image" content={`${siteUrl}${image}`} /> */}
      {/* facebook */}
      <meta property="og:url" content={siteUrl} />
      {/* <meta property="og:type" content={website} /> */}
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      {/* <meta property="og:image" content={`${siteUrl}${image}`} />
			<meta property="og:image:width" content="400" />
			<meta property="og:image:width" content="300" /> */}
    </Helmet>
  )
}

export default SEO
