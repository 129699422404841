import "../layout/cssgs"
// import ScriptTag from 'react-script-tag';
import Helmet from "react-helmet"

const Searchbar = () => {
  return (
    <div className="searchbar0">
      <Helmet>
        <script
          async
          src="https://cse.google.com/cse.js?cx=000799623710252977384:daej5fqa_3c"
        ></script>
      </Helmet>

      <div className="gcse-searchbox"></div>

      <div className="gcse-searchresults"></div>
    </div>
  )
}
export default Searchbar
